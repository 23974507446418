import axios, { AxiosRequestConfig } from "axios";

// Define the API base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPersonalScoreByUserId = async () => {
  try {
    const userId =
      localStorage.getItem("InvitedUserId") == null
        ? localStorage.getItem("userId")
        : localStorage.getItem("InvitedUserId");
    console.log("userId", userId);

    const access = localStorage.getItem("accessToken");
    console.log("Access Token: ", access);

    const initialConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/v1/users/get-invited-user/${userId}`,
      initialConfig
    );
    console.log("Data: ", response);
    console.log("response", response.data);

    return response.data;
  } catch (error) {
    // Handle the error appropriately
    console.error("Error While fetching personal score:", error);
    throw error;
  }
};

export const updateTotalScoreByUserId = async () => {
  try {
    const userId = localStorage.getItem("InvitedUserId");
    console.log("userId", userId);

    const access = localStorage.getItem("accessToken");
    console.log("Access Token: ", access);

    const initialConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/v1/users/get-invited-user/${userId}`,
      initialConfig
    );
    console.log("Data: ", response);
    console.log("response", response.data);

    return response.data;
  } catch (error) {
    // Handle the error appropriately
    console.error("Error While fetching personal score:", error);
    throw error;
  }
};
